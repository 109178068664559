import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";


const BizDevReps = ({pageContext}) => {
    return <Layout
        pageContext={pageContext}
    >
        <Seo title={"Business Development"} />
        <div className="content-row bizdev">
            <div className="seventy">
                <h1>Join the VanWalks Sales Rep Team</h1>
                <p className="sub-title">
                    Join the VanWalks Sales Rep team and <b>earn a commission</b> on every sale you make! Our program is perfect for individuals who are passionate about exploring Vancouver and helping others discover its beauty. As a VanWalks Sales Rep, you&apos;ll have the opportunity to share your love for the city with others and <b>earn money doing it</b>.
                </p>
                <h2>Easy-to-Use Sales Portal</h2>
                <div className="sales-portal-container">
                    <p className="sub-title">

                        <StaticImage
                            className="screenshot screenshot-reps"
                            src="../../images/VanWalksRepsScreenshot.jpg"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt="VanWalks Rep Page Screenshot"
                            objectFit="contain"
                            placeholder={"none"}
                        />
                    Our program is a great add-on for your existing customers, providing them with a unique and exciting way to experience Vancouver. Plus, with our <b>easy-to-use sales portal</b>, you&apos;ll have everything you need to start selling VanWalks tours and <b>earning commissions</b> in no time.
                    </p>
                </div>
                <h2>Easy-to-Use Web App</h2>
                <p className="sub-title">
                    At VanWalks, we believe in making it easy for everyone to explore Vancouver at their own pace. That&apos;s why our web app is designed to be incredibly user-friendly, with no app to download, no accounts to create, and no passwords to remember.
                </p>
                <p className="sub-title">
                    With VanWalks, users can start their tour right away, without any hassle or delay. This means that the time to user engagement is very fast, ensuring that your customers have a positive experience from the very beginning.
                </p>
                <h1>Sign Up Now</h1>
                <p className="sub-title">
                    Don&apos;t miss out on this exciting opportunity to become a part of the VanWalks team. Email us at <a href="mailto:info@vanwalks.ca">info@vanwalks.ca</a> and start earning today (or maybe tomorrow)!
                </p>
            </div>
            <div className="thirty screenshot-bizdev">
                <StaticImage
                    className="screenshot screenshot-1"
                    src="../../images/home_screenshot_1.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
                <StaticImage
                    className="screenshot screenshot-2"
                    src="../../images/home_screenshot_2.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="VanWalks Screenshot"
                    objectFit="contain"
                    placeholder={"none"}
                />
            </div>
        </div>
    </Layout>
}

export default BizDevReps